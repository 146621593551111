import React, {useState} from 'react';

import './App.css';

import NavBar from './components/static/NavBar';
function App() {
  const [ano, setAno] = useState(new Date().getFullYear());
  

  return (
    <div className="App">
        <NavBar></NavBar>
      <header className="App-header">
        <p>Em Breve</p>
        <p><small>Site em desenvolvimento...</small></p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Desenvolvido por belogrid
        </a>
        <p>&copy; {ano}</p>
      </header>
    </div>
  );
}

export default App;
