import React from 'react'
import Logo from '../../assets/app_logo.png';

export default function NavBar() {
    return (
        <div>
            <nav>
               
                <a id="logo-tipo" className="App-link" href="#"> <img src={Logo} width="48px" alt="Belogrid Logo" />belogrid</a>
                <ul>
                    <li>Principal</li>
                    <li>Sobre</li>
                    <li>Serviços</li>
                    <li>Contato</li>
                </ul>
            </nav>
        </div>
    )
}
